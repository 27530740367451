.App {
  text-align: center;
}
.payment-container {
  display: flex;
  justify-content: center;
  padding-bottom: 3.5rem;
}

.payment-wrapper {
  padding: 2rem;
  max-width: 415px;
  width: 100%;
}

.back-link {
  color: #fff;
  font-size: 1.25rem;
  /* margin-bottom: 0rem; */
  display: block;
  text-decoration: none;
  border-radius: 5px;
  border: none;
  padding: 10px 20px;
  background-color: #007bff;
}

.title {
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 1.5rem;
}

.package-details {
  display: flex;
  justify-content: space-between;
  /* margin-bottom: 0.5rem; */
  width: 415px;
  margin-left: auto;
  margin-right: auto;
}

.package-info,
.payment-info {
  text-align: left;
}

.label {
  color: #6b7280;
  font-size: 1rem;
}

.value {
  font-size: 2rem;
  font-weight: bold;
}

.payment-icons {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 2.5rem;
  gap: 0.5rem;
}

.icon-large {
  height: 4rem;
}

.icon-small {
  height: 2rem;
}

/* Media Queries for Responsiveness */
@media (max-width: 640px) {
  .title {
    font-size: 1.75rem;
  }

  .package-details {
    flex-direction: column;
  }

  .label {
    font-size: 0.875rem;
  }

  .value {
    font-size: 1.5rem;
  }

  .payment-icons {
    flex-direction: column;
    gap: 1rem;
  }
}

.coupon-container {
  width: 100%;
  margin-bottom: 20px;
}

.coupon-input-wrapper {
  display: flex;
  align-items: center;
}

.coupon-input {
  flex-grow: 1;
  padding: 8px 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px;
}

.coupon-apply-btn {
  margin-left: 10px;
  padding: 6px 12px;
  background-color: white;
  color: #007bff;
  border: 1px solid #007bff;
  border-radius: 4px;
  font-size: 14px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.coupon-apply-btn:hover {
  background-color: #f0f8ff;
}

.coupon-apply-btn:disabled {
  opacity: 0.6;
  cursor: not-allowed;
}

/* App.css */
